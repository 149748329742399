import { gql } from "@apollo/client";

export const UPDATE_PAYMENT_PROCESSOR_MUTATION = gql`
  mutation UpdatePaymentProcessor(
    $paymentProcessor: PaymentProcessorAttributes!
  ) {
    updatePaymentProcessor(input: $paymentProcessor) {
      id
      label
      apiKey
      apiSecret
      storeProcessorId
      outletPaymentProcessors {
        id
        outlet {
          id
          label
        }
      }
    }
  }
`;
