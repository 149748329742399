import { gql } from "@apollo/client";

export const PAYMENT_PROCESSORS_QUERY = gql`
  query GetPaymentProcessors {
    paymentProcessors {
      id
      label
      storeProcessorId
      outlets {
        id
        label
      }
    }
  }
`;
