import { gql } from "@apollo/client";

export const PAYMENT_PROCESSOR_QUERY = gql`
  query GetPaymentProcessor($id: Int!) {
    paymentProcessor(id: $id) {
      id
      label
      apiKey
      apiSecret
      storeProcessorId
      outletPaymentProcessors {
        id
        outlet {
          id
          label
        }
      }
    }
  }
`;
